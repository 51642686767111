import './App.css';
import Weather from './components/Weather';
function App() {
  return (
    <>
        <Weather />
    </>
  );
}

export default App;
